import { storeAnswer } from '../../services/manager';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import Markdown from '../../components/Markdown';
import SessionScreen from './SessionScreen';
import VideoPlayer from '../../components/VideoPlayer'
import { useEffect } from 'react';


export default function Screen(props: WrapperScreenProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    props.useAudio('');
  });

  async function answer() {
    await storeAnswer('6', t('screens:6.uuid'));
    navigate('/session/23');
  }

  const actions = (<>
    <Button label={t('continue')} variant="primary" onClick={() => answer()} />
  </>);

  return (
    <SessionScreen
      screen="6"
      title={`${t('screens:6.step')} ${t('screens:6.title')}`}
      chapter={1}
      progress={40}
      actions={actions}
    >
      <VideoPlayer src={t('screens:6.source')} poster={t('screens:6.poster')} />
    </SessionScreen>
  );
};
