import { storeAnswer } from '../../services/manager';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import Markdown from '../../components/Markdown';
import SessionScreen from './SessionScreen';
import VideoPlayer from '../../components/VideoPlayer'
import { useEffect } from 'react';

export type StoredValue = string;

export default function Screen(props: WrapperScreenProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  async function answer(doIntro: String) {
    await storeAnswer('85', t('screens:85.uuid'), doIntro as StoredValue);
    navigate('/session/74', { replace: true });
  }

  // async function goBack() {
  //   navigate(-1);
  // }

  useEffect(() => {
    props.useAudio('');
  });

  const actions = (<>
    {/* <Button label={t('go_back')} variant="secondary" onClick={() => goBack()} /> */}
    <Button label={t('continue')} variant="primary" onClick={() => answer('true')} />
  </>);

  return (
    <SessionScreen
      screen="85"
      title={`${t('screens:85.step')} ${t('screens:85.title')}`}
      chapter={6}
      progress={0}
      actions={actions}
    >
      <VideoPlayer src={t('screens:85.source')} poster={t('screens:85.poster')} />
    </SessionScreen>
  );
};
