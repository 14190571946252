import { storeAnswer } from '../../services/manager';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import Markdown from '../../components/Markdown';
import SessionScreen from './SessionScreen';
import VideoPlayer from '../../components/VideoPlayer'
import { useEffect } from 'react';

export type StoredValue = string;
export default function Screen(props: WrapperScreenProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  async function answer(doIntro: String) {
    await storeAnswer('83', t('screens:83.uuid'), doIntro as StoredValue);
    navigate('/session/35', { replace: true });
  }

  useEffect(() => {
    props.useAudio('');
  });

  // async function goBack() {
  //   navigate(-1);
  // }

  const actions = (<>
    {/* <Button label={t('go_back')} variant="secondary" onClick={() => goBack()} /> */}
    <Button label={t('continue')} variant="primary" onClick={() => answer('true')} />

  </>);

  return (
    <SessionScreen
      screen="83"
      title={`${t('screens:83.step')} ${t('screens:83.title')}`}
      chapter={2}
      progress={45}
      audio={t('screens:83.audio')}
      actions={actions}
    >
      <VideoPlayer src={t('screens:83.source')} poster={t('screens:83.poster')} />
    </SessionScreen>
  );
};
